// media queries
//max-width
@mixin phone-small {
    @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) {
        @content;
    }
}

@mixin phone {
    @media only screen and (max-width: 480px) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: 768px) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (max-width: 992px) {
        @content;
    }
}

@mixin large-desktop {
    @media only screen and (max-width: 1200px) {
        @content;
    }
}

// min-width
@mixin phone-mw {
    @media only screen and (min-width: 576px) {
        @content;
    }
}

@mixin tablet-mw {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

@mixin desktop-mw {
    @media only screen and (min-width: 992px) {
        @content;
    }
}

@mixin large-desktop-mw {
    @media only screen and (min-width: 1200px) {
        @content;
    }
}

// media queries