.template-wrap {
    display: flex;
    align-items: stretch;
    overflow: hidden;
    height: 100%;
    background: #F5F6F8;
    position: relative;
    .content {
        width: 100%;
        height: 100%;
        transition: all .3s;
        .global-search {
            position: relative;
            margin-left: 40px;
            i.fa.fa-search.search-ico {
                position: absolute;
                color: #90A0B7;
                font-size: 12px;
            }
        }
        .navbar- {
            .form-control {
                border: none;
                color: black;
                font-size: 14px;
                padding-left: 25px;
                &:focus {
                    box-shadow: none;
                    border-bottom: 1px solid #D3D8DD !important;
                    border-radius: 0;
                }
            }
        }
        .notification-right {
            text-align: right;
            color: #90A0B7;
            position: relative;
            font-size: 14px;
            cursor: pointer;
            &:hover {
                color: rgb(137, 154, 179);
            }
        }
        .full-space {
            .table-wrap {
                width: 100%;
                height: 100%;
                background-color: #fff;
                box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
                padding: 20px;
            }
        }
        .logout {
            font-size: 14px;
            color: rgb(137, 154, 179);
            text-align: right;
            cursor: pointer;
            &:hover {
                color: black;
            }
        }
    }
    .navbar- {
        height: 60px;
        position: relative;
        display: flex;
        align-items: center;
        // justify-content: flex-end;
        padding: 5px 15px;
        background: #fff;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #EBEFF2;
    }
    .full-space {
        width: 100%;
        height: calc(100% - 60px);
    }
    .full-space-inner {
        padding: 15px;
        height: 100%;
        overflow: auto;
    }
    .branding {
        padding: 17px;
        border-bottom: 1px solid #EBEFF2;
        text-align: center;
        cursor: pointer;
    }
    .navbar-actions {
        list-style: none;
        height: auto;
        border-bottom: 1px solid #EBEFF2;
    }
    .user-prof {
        display: flex;
        padding-left: 15px;
        .avatar {
            img {
                height: 46px;
                width: 46px;
                border-radius: 50%;
                background-color: #F5F6F8;
                background-size: cover;
                margin-right: 15px;
                object-fit: cover;
            }
        }
        .user-name {
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            color: #192A3E;
            margin-top: 5px;
        }
        .user-email {
            font-size: 11px;
            line-height: 16px;
            font-weight: 500;
            color: #90A0B7;
        }
    }
    .nav- {
        margin-top: 40px;
        .navbar {
            .active {
                color: #109CF1;
                font-weight: 600;
            }
            justify-content: left;
            a {
                font-size: 13px;
            }
        }
        .icon-dash {
            margin-right: 15px;
        }
    }
    .nav-settings {
        padding: 15px;
        .navbar {
            .active {
                color: #109CF1;
                font-weight: 600;
            }
            justify-content: left;
            a {
                font-size: 13px;
            }
        }
        .icon-dash {
            margin-right: 15px;
        }
    }
    .width-logout {
        width: 100px;
        text-align: right;
        border-left: 1px solid #90A0B7;
    }
    .width-notification {
        width: 100px;
        margin-right: 20px;
    }
    .width-search {
        width: 100%;
    }
}

.side-nav {
    width: 256px;
    background: #FFFFFF;
    box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
    z-index: 1;
    position: relative;
    min-height: 100%;
    @include tablet {
        display: none;
    }
    .side-bar-toggle {
        position: absolute;
        top: 18px;
        left: 15px;
    }
}

.side-bar-toggle-res {
    margin-right: 15px;
    position: absolute;
    z-index: 10;
    top: 15px;
    left: 15px;
}