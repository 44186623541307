.saved-filter-title {
    font-size: 12px;
    width: 100%;
    ul {
        padding-left: 0;
        li {
            list-style: none;
            padding-left: 0;
            cursor: pointer;
            line-height: 24px;
            &:hover {
                color: #109CF1;
            }
        }
    }
    li.active {
        font-weight: 600;
    }
}

.delete-filter {
    text-align: right;
    font-size: 12px;
    cursor: pointer;
}

.nav-link.no-p {
    padding-left: 0;
}

.bin {
    text-align: right;
    cursor: pointer;
    margin-top: 5px;
    .fas {
        color: #c5cace;
        font-size: 14px;
        &:hover {
            color: #707683;
        }
    }
}
.filter-title-checked{
    margin-left: 15px;
    .fas{
margin-top: 6px;
color: #2ED47A;
    }
}
button.btn.btn-light.custom {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    width: fit-content;
    height: 30px;
    border: 1px solid #CBD5E0;
}

.modal-content.center {
    .nav-pills .nav-link.active {
        color: #000000 !important;
        font-weight: 600;
        border-bottom: solid 2px #109CF1;
        border-radius: 0;
    }
}

.input-custom {
    color: black;
    border-bottom: 1px solid #D3D8DD !important;
    width: 100%;
    border-radius: 0;
    text-align: left;
    font-size: 12px;
    background: white;
    padding-left: 0;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    position: relative;
    cursor: pointer;
    padding: 5px 0;
    &:focus {
        border-bottom: 1px solid #109CF1 !important;
        outline: none;
    }
}

button.applyBtn.btn.btn-sm.btn-primary {
    width: fit-content;
    height: 30px;
    font-size: 12px;
}

.multi-select-custom {
    font-size: 12px;
}

.notice {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #707683;
    @include tablet {
        margin-top: 20px;
    }
}

.custom-height-top {
    @include tablet {
        margin-bottom: 20px;
    }
}

.custom-height {
    @include tablet {
        margin-bottom: 20px;
    }
}

.modal-dialog.right {
    /* position: absolute; */
    /* top: 50%; */
    /* bottom: 50%; */
    position: absolute;
    top: 50%;
    /* left: 50%; */
    transform: translate(0%, -50%) !important;
    right: 0;
}

.modal-dialog.center.small {
    max-width: 400px!important;
}

// custom modal
.custom-backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #000000;
    top: 0;
    z-index: 9;
    opacity: 0.6;
}

.custom-modal {
    width: 520px;
    background-color: #fff;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    height: 100%;
    padding: 20px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
    .header-custom {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
    }
    .body-custom {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        height: calc(100% - 20%);
        overflow-x: hidden;
        width: auto;
        overflow-y: auto;
        padding-top: 20px;
    }
    .footer-custom {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
    }
}

// custom modal center
.custom-modal.center {
    width: 520px;
    background-color: #fff;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    height: 100vh;
    padding: 20px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    .header-custom {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
    }
    .body-custom {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        height: calc(100% - 20%);
        overflow-x: hidden;
        width: auto;
        overflow-y: auto;
        padding-top: 20px;
    }
    .footer-custom {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
    }
}