// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Fontawesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';

// Bootstrap Date Range Picker
@import '~bootstrap-daterangepicker';

// Bootstrap Select
@import '~bootstrap-select/sass/bootstrap-select.scss';

//tags input
@import '~bootstrap4-tagsinput-douglasanpa/tagsinput';

//bootstrap toggle
@import '~bootstrap-toggle/css/bootstrap-toggle';

//bootstrap Slider
@import '~bootstrap-slider/src/sass/bootstrap-slider.scss';

// Variables
@import '../sass/variables';
// Commen 
@import '../sass/common';
// breadcrumbs 
@import '../sass/breadcrumbs';
// Login
@import '../sass/login';
// Top-bar
@import '../sass/top-bar';
// Nav
@import '../sass/nav';
// Mixings
@import '../sass/mixings';
// Media
@import '../sass/media';
// Pages
@import '../sass/pages';
// campaign Pages
@import '../sass/campaign-page';
// Dashboard
@import '../sass/dashboard';
// Browser Specific
@import '../sass/browser-specific';
// Badges
@import '../sass/badges';
// Alerts
@import '../sass/alerts';
// Forms
@import '../sass/_layout/forms';
// Overides
@import '../sass/_base/overides';
// Typo
@import '../sass/_base/typo';
// Grid
@import '../sass/_layout/grid';
// Tables
@import '../sass//_layout/tables';
//Modal
@import '../sass/Modal';