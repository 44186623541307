@import '../sass/mixings';
//main tabs nav
.main-tabs {
    position: relative;
    .nav-item {
        position: relative;
        .nav-link.active {
            color: #000000 !important;
            background: none;
            position: relative;
            border-bottom: 1px solid #098EDF;
            border-radius: 0;
            font-weight: 600;
            // &::after {
            //     content: '';
            //     position: absolute;
            //     width: 10px;
            //     height: 10px;
            //     background: #109CF1;
            //     border-radius: 10px;
            //     bottom: -5px;
            //     right: 35px;
            // }
        }
        .nav-link {
            color: black;
            font-size: 14px;
            font-weight: 500;
            // background: #F5F6F8;
        }
    }
    .add-canvas {
        text-align: right;
        .btn-primary {
            width: fit-content;
            font-size: 11px;
            background: #098EDF;
            box-shadow: 0px 2px 6px rgba(9, 142, 223, 0.3);
            border-radius: 4px;
            height: 32px;
        }
    }
    .canvas-view {
        flex-direction: row-reverse;
        margin-top: 10px;
        .grid {
            margin-right: 10px;
            color: #C2CFE0;
            cursor: pointer;
            &:hover {
                color: rgb(183, 195, 209);
            }
        }
        .list {
            margin-right: 10px;
            color: #C2CFE0;
            cursor: pointer;
            &:hover {
                color: rgb(183, 195, 209);
            }
        }
    }
}

.sidenav-wrapper {
    // ul {
    //     padding: 0;
    //     li {
    //         list-style: none;
    //         font-size: 0.9em;
    //         .expandable {
    //             position: relative;
    //             //expand (down) arrow
    //             &:before,
    //             &:after {
    //                 border: solid transparent;
    //                 content: " ";
    //                 height: 0;
    //                 left: 88%;
    //                 position: absolute;
    //                 pointer-events: none;
    //                 top: 42%;
    //                 width: 0;
    //             }
    //             &:before {
    //                 border-top-color: $white;
    //                 border-width: 9px;
    //                 margin-left: -10px;
    //             }
    //             &:after {
    //                 border-top-color: #098EDF;
    //                 border-width: 8px;
    //                 margin-left: -8px;
    //             }
    //             &:hover:after {
    //                 border-top-color: #098EDF;
    //             }
    //         }
    //     }
    //     .expandable,
    //     a {
    //         background: white;
    //         color: black;
    //         display: inline-block;
    //         text-decoration: none;
    //         padding: 10px 0 10px 10px;
    //         width: 200px;
    //         font-size: 12px;
    //         &:hover {
    //             background-color: #C2CFE0;
    //             color: #109CF1;
    //         }
    //     }
    //     .expandable.expanded+ul {
    //         display: block;
    //     }
    //     .expandable.expanded {
    //         //minimize (up) arrow
    //         &:after,
    //         &:before {
    //             top: 12%;
    //             left: 88%;
    //             border: solid transparent;
    //             content: " ";
    //             height: 0;
    //             width: 0;
    //             position: absolute;
    //             pointer-events: none;
    //         }
    //         &:after {
    //             border-bottom-color: #098EDF;
    //             border-width: 9px;
    //             margin-left: -12px;
    //         }
    //         // &:before {
    //         //     border-bottom-color: #098EDF;
    //         //     border-width: 10px;
    //         //     margin-left: -10px;
    //         // }
    //         &:hover:after {
    //             border-bottom-color: #098EDF;
    //             border-top-color: transparent;
    //         }
    //     }
    //     ul {
    //         display: none;
    //         .expandable,
    //         a {
    //             background: white !important;
    //             padding-left: 20px;
    //             width: 190px;
    //             //arrow down
    //             &:after {
    //                 border-top-color: #098EDF;
    //             }
    //         }
    //         .expandable.expanded,
    //         a {
    //             &:after {
    //                 border-bottom-color: #098EDF;
    //             }
    //         }
    //         ul {
    //             display: none;
    //             padding-left: 0;
    //             a {
    //                 background: white !important;
    //                 color: black;
    //                 padding-left: 40px;
    //                 width: 170px;
    //             }
    //         }
    //     }
    // }
    height: 100vh;
    .dash-navbar-top {
        margin-bottom: 0;
        min-height: 61px;
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 0;
        line-height: 1;
    }
    .dash-navbar-top .nb-btn-toggle {
        position: absolute;
        left: 16px;
        top: 13.5px;
        font-size: 21px;
        background-color: rgba(0, 0, 0, 0);
        border-width: 0;
        color: #d3d1dd;
        outline: 0;
    }
    @media (min-width: 768px) {
        .dash-navbar-top.nb-visible .nb-btn-toggle {
            display: none;
        }
    }
    .dash-navbar-top .navbar-toggle {
        border-width: 0;
        font-size: 24px;
        color: #d3d1dd;
        padding: 0 10px;
        margin-top: 14px;
        margin-right: 15px;
    }
    .dash-navbar-top .navbar-toggle:hover,
    .dash-navbar-top .navbar-toggle:focus {
        background-color: transpardnt;
    }
    /* Dash Navbar Left = nb
  / -------------------------------------------------- */
    .dash-navbar-left {
        // position: absolute;
        background-color: #FFF;
        width: 256px;
        top: 0;
        bottom: 0;
        overflow-y: auto;
        z-index: 999;
        padding-top: 5px;
        -webkit-transition: left 300ms;
        -o-transition: left 300ms;
        transition: left 300ms;
    }
    .navbar-brand {
        display: block;
        background: #109CF1;
        width: 100%;
        color: #FFF;
        text-transform: uppercase;
        padding: 0 20px;
        margin: -10px 0 30px 0;
        height: 65px;
        line-height: 65px;
    }
    .navbar-brand:hover {
        color: #FFF;
    }
    .navbar .form-inline {
        margin-top: 12px;
    }
    .navbar .btn {
        background: orange;
        color: #FFF;
    }
    @media (max-width: 767px) {
        .dash-navbar-left.nb-visible {
            left: -250px;
        }
        .dash-navbar-left.nb-visible.nb-show {
            left: 0;
        }
    }
    .dash-navbar-left.nb-hidden {
        left: -250px;
    }
    .dash-navbar-left.nb-hidden.nb-show {
        left: 0;
    }
    .dash-navbar-left .nb-nav-title {
        text-transform: uppercase;
        color: rgb(201, 207, 216);
        border-bottom: 1px solid #EBEFF2;
        padding: 10px 25px;
        font-size: 11px;
        letter-spacing: 1px;
    }
    .dash-navbar-left .nb-nav {
        padding-left: 0;
        list-style: none;
    }
    .dash-navbar-left .nb-nav ul {
        padding-left: 0;
        list-style: none;
    }
    .dash-navbar-left .nb-nav li {
        position: relative;
    }
    .dash-navbar-left .nb-nav li a {
        display: block;
        outline: 0;
        padding: 8px 25px 7px;
        font-size: 14px;
        color: #1A202C;
    }
    .dash-navbar-left .nb-nav li a:hover,
    .dash-navbar-left .nb-nav li a:focus {
        text-decoration: none;
        background-color: #F5F6F8;
        -webkit-box-shadow: inset 3px 0 0 #098EDF;
        box-shadow: inset 3px #098EDF;
        color: #098EDF;
    }
    .dash-navbar-left .nb-nav li a:hover .badge,
    .dash-navbar-left .nb-nav li a:focus .badge {
        color: #EEE;
    }
    .dash-navbar-left .nb-nav li a>.nb-link-icon {
        padding-right: 8px;
    }
    .dash-navbar-left .nb-nav li a>.nb-btn-sub-collapse {
        position: absolute;
        right: 25px;
        top: 10px;
    }
    .dash-navbar-left .nb-nav li a>.badge {
        position: absolute;
        right: 24px;
        top: 6px;
        padding: 3px 10px;
        color: #99a6b7;
        background-color: transparent;
        border: 1px solid #EBEFF2;
    }
    .dash-navbar-left .nb-nav li a.collapsed .nb-btn-sub-collapse {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    .dash-navbar-left .nb-nav li.active>a {
        background-color: #F5F6F8;
        -webkit-box-shadow: inset 3px 0 0 #ffffff;
        box-shadow: inset 3px 0 0 #098EDF;
        color: #098EDF;
    }
    .dash-navbar-left .nb-nav li .nb-sub-one {
        background-color: #ffffff;
    }
    .dash-navbar-left .nb-nav li .nb-sub-one li {
        border-top: 1px solid #EBEFF2;
    }
    .dash-navbar-left .nb-nav li .nb-sub-one li a {
        display: block;
        color: #1A202C;
    }
    .dash-navbar-left .nb-nav li .nb-sub-one li a:hover,
    .dash-navbar-left .nb-nav li .nb-sub-one li a:focus {
        color: #eeeeee;
        background-color: #F5F6F8;
        -webkit-box-shadow: inset 3px 0 0 #098EDF;
        box-shadow: inset 3px 0 0 #098EDF;
        color: #098EDF;
    }
    .dash-navbar-left .nb-nav li .nb-sub-one li a>.nb-link-icon {
        padding-right: 8px;
    }
    .dash-navbar-left .nb-nav li .nb-sub-one li a>.badge {
        color: #098EDF;
        background-color: transparent;
        border: 1px solid #098EDF;
    }
    .dash-navbar-left .nb-nav li .nb-sub-one li.active>a {
        background-color: #1c1b24;
        -webkit-box-shadow: inset 3px 0 0 #1c1b24;
        box-shadow: inset 3px 0 0 #1c1b24;
    }
    .dash-navbar-left .nb-nav li .nb-sub-two {
        background-color: #211f2a;
    }
    .dash-navbar-left .nb-nav li .nb-sub-two li {
        border-top-color: 1px solid #282533;
    }
    .dash-navbar-left .nb-nav li .nb-sub-two li a {
        color: #a7a9ac;
    }
    .dash-navbar-left .nb-nav li .nb-sub-two li a:hover,
    .dash-navbar-left .nb-nav li .nb-sub-two li a:focus {
        color: #eeeeee;
        background-color: #282533;
        -webkit-box-shadow: inset 3px 0 0 #282533;
        box-shadow: inset 3px 0 0 #282533;
    }
    .dash-navbar-left .nb-nav li .nb-sub-two li a>.badge {
        color: #a7a9ac;
        background-color: transparent;
        border: 1px solid #211f2a;
    }
    .dash-navbar-left .nb-nav li .nb-sub-two li.active>a {
        background-color: #1c1b24;
        -webkit-box-shadow: inset 3px 0 0 #1c1b24;
        box-shadow: inset 3px 0 0 #1c1b24;
    }
    /* Content wrap
  / -------------------------------------------------- */
    .content-wrap {
        position: relative;
    }
    .content-wrap.nb-visible {
        margin-left: 250px;
    }
    @media (max-width: 767px) {
        .content-wrap.nb-visible {
            margin-left: 0;
            -webkit-transition: all 300ms;
            -o-transition: all 300ms;
            transition: all 300ms;
        }
        .content-wrap.nb-visible.nb-push {
            -webkit-transform: translate(250px, 0);
            -ms-transform: translate(250px, 0);
            -o-transform: translate(250px, 0);
            transform: translate(250px, 0);
        }
    }
    .content-wrap.nb-hidden {
        margin-left: 0;
        -webkit-transition: all 300ms;
        -o-transition: all 300ms;
        transition: all 300ms;
    }
    .content-wrap.nb-hidden.nb-push {
        margin-left: 250px;
    }
    @media (max-width: 767px) {
        .content-wrap.nb-hidden.nb-push {
            margin-left: 0;
            -webkit-transform: translate(250px, 0);
            -ms-transform: translate(250px, 0);
            -o-transform: translate(250px, 0);
            transform: translate(250px, 0);
        }
    }
    .content-wrap.content-opacity.nb-overlay,
    .content-wrap.content-opacity.nb-push {
        opacity: 0.3;
        filter: alpha(opacity=30);
    }
}

.expanded {
    display: none;
}