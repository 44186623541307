.table-wrap {
    position: relative;
    table {
        width: 100%;
        border-collapse: collapse;
    }
    /* Zebra striping */
    tr:nth-of-type(odd) {}
    th {
        color: #99a6b7;
        font-size: 14px;
        font-weight: 500;
        background-color: white;
    }
    td,
    th {
        padding: 10px;
        border-bottom: 1px solid #EBEFF2;
        text-align: left;
        font-size: 14px;
    }
    /* 
    Max width before this PARTICULAR table gets nasty
    This query will take effect for any screen smaller than 760px
    and also iPads specifically.
    */
    @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
        table {
            width: 100%;
        }
        /* Force table to not be like tables anymore */
        table,
        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
            cursor: pointer;
        }
        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }
        tr {
            cursor: pointer;
            border: 1px solid #ccc;
        }
        td {
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50%;
            &:hover {
                background: #FFFFFF;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
            }
        }
        td:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 6px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
            /* Label the data */
            content: attr(data-column);
            color: #000;
            font-weight: bold;
            font-size: 12px;
            color: #99a6b7;
        }
    }
    .form-check {
        .custom-checkbox {
            background-color: white;
            border: 1px solid black;
        }
    }
    // tr.hover {
    //     cursor: pointer;
    //     &:hover {
    //         background: #FFFFFF;
    //         box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    //     }
    // }
    span.sort {
        .fas {
            margin-left: 5px;
            cursor: pointer;
            color: #AEAEAE;
        }
    }
}

td.name-td {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #323C47;
    cursor: pointer;
    a {
        color: #323C47!important;
    }
    &:hover {
        color: #109CF1;
    }
    span.phone{
        .fas{
            font-size: 9px;
            color: #AEAEAE;
            margin-left: 15px;
            &:hover {
                color: #109CF1;
            }
        }
    }
}


tr.highlight:nth-child(even) {background: #F5F6F8}
tr.highlight:nth-child(odd) {background: #FFF}


thead.bold{
    th{
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
    }
}

