label {
    font-size: 11px;
    color: #818E9B;
    font-weight: 500;
    margin: 0;
}

.dropdown-menu.show {
    font-size: 12px !important;
    width: 100%;
    z-index: 10 !important;
    .inner.show{
        max-height: 230px !important;
    }

}

.modal-body {
    overflow: auto;
}

.modal-content {
    height: 100vh;
    .modal-title {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */
        letter-spacing: 0.01em;
        color: #000000;
    }
}

.add-canvas-modal {
    height: 100%;
    .form-group {
        .form-control.with-border {
            border-bottom: 1px solid #D3D8DD;
            padding-left: 0;
            border-radius: 0;
            width: 100%;
            color: black;
        }
    }
}

.modal-dialog {
    max-width: 600px;
    position: absolute;
    right: 0;
    width: 100%;
    top: 0;
    margin: 0;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    border-radius: 0;
    outline: 0;
}

.modal-footer {
    .btn-primary {
        width: 100%;
        height: 32px;
        font-size: 12px;
        background: #098EDF;
        box-shadow: 0px 2px 6px rgba(9, 142, 223, 0.3);
        border-radius: 4px;
    }
}

//forms 
.form-control {
    padding-left: 0;
    width: 100%;
    border-bottom: 1px solid #D3D8DD !important;
    border-radius: 0;
    border: none;
    color: black;
    font-size: 14px;
}

.drop-down {
    color: black;
    border-bottom: 1px solid #D3D8DD !important;
    width: 100%;
    border-radius: 0;
    text-align: left;
    font-size: 12px;
    background: white;
    padding-left: 0;
    &:focus {
        border-bottom: 1px solid #80bdff !important;
        outline: 0;
    }
}

.dropdown-toggle::after {
    position: absolute;
    right: 15px;
    // top: 40px;
    color: #AEAEAE;
}

// Check box - Drop down
.checkbox {
    cursor: pointer;
}

.checkbox label:after,
.radio label:after {
    content: '';
    display: table;
    clear: both;
}

.checkbox .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .25em;
    width: 1.3em;
    height: 1.3em;
    float: left;
    margin-right: .5em;
}

.radio .cr {
    border-radius: 50%;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    font-size: .7em;
    line-height: 0;
    top: 50%;
    left: 20%;
}

.radio .cr .cr-icon {
    margin-left: 0;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
    display: none;
}

.checkbox label input[type="checkbox"]+.cr>.cr-icon,
.radio label input[type="radio"]+.cr>.cr-icon {
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    transition: all .3s ease-in;
}

.checkbox label input[type="checkbox"]:checked+.cr>.cr-icon,
.radio label input[type="radio"]:checked+.cr>.cr-icon {
    transform: scale(1) rotateZ(0deg);
    opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled+.cr,
.radio label input[type="radio"]:disabled+.cr {
    opacity: .5;
}

// datepicker
.input-group.date {
    cursor: pointer;
    span.input-group-addon {
        border-bottom: 1px solid #D3D8DD;
        font-size: 14px;
        color: #C2CFE0;
    }
    &:hover {
        color: #109CF1;
    }
}

// nav tabs
.nav-link {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    letter-spacing: 0.01em;
    color: #90A0B7 !important;
    .fas {
        font-size: 14px;
        margin-right: 8px;
    }
}

.nav-pills .nav-link.active {
    color: #000000 !important;
    background: none;
    position: relative;
    border-bottom: 2px solid #098EDF;
    border-radius: 0;
    font-weight: 600;
    .fas {
        color: #109CF1;
        font-size: 14px;
        margin-right: 8px;
    }
    // &::after {
    //     content: "";
    //     position: absolute;
    //     width: 10px;
    //     height: 10px;
    //     background: #109CF1;
    //     border-radius: 10px;
    //     top: 12px;
    //     left: 0;
    //     display: block;
    // }
}

.action-tabs {
    .nav-pills .nav-link.active {
        &::after {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            background: #109CF1;
            border-radius: 10px;
            top: 12px;
            left: 0;
            display: none;
            @include phone {
                display: none;
            }
        }
    }
}

//alerts
.alert {
    text-align: center;
    font-size: 12px;
}

.page-item.active .page-link {
    background: #109CF1;
    border-color: rgb(19, 156, 241);
}

.page-link {
    color: #109CF1;
    font-size: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
}

.advance-search {
    .dropdown.dropdown-lg .dropdown-menu {
        margin-top: 5px;
        padding: 20px;
        background: #FFFFFF;
        box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
        border-radius: 3px;
        border: none;
        margin-top: 25px;
        margin-left: 10px;
    }
    .input-group-btn .btn-group {
        width: 50px;
        height: 40px;
        display: flex !important;
    }
    .btn-group- .btn {
        border-radius: 0;
        margin-left: -1px;
        width: 50px;
        height: 40px;
    }
    .btn-group .btn:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .btn-group .form-horizontal .btn[type="submit"] {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .form-horizontal .form-group {
        margin-left: 0;
        margin-right: 0;
    }
    .form-group .form-control:last-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    @media screen and (min-width: 768px) {
        #adv-search {
            width: 445px;
        }
        .dropdown.dropdown-lg {
            position: static !important;
        }
        .dropdown.dropdown-lg .dropdown-menu {
            width: 730px;
        }
    }
    .form-control.adv-search {
        background: #FFFFFF;
        border-radius: 4px;
        height: 40px;
        padding-left: 10px;
        border: 1px solid #CBD5E0;
    }
    .input-group-btn {
        background: white;
        border-left: 0.3px solid #90A0B7;
        width: 50px;
    }
    .filter-title {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #000000;
        width: 100%;
        margin-bottom: 10px;
    }
    .clear-filter {
        justify-content: space-between;
        text-align: right;
        width: 100%;
        margin-right: 15px;
        margin-top: 13px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 21px;
        letter-spacing: 0.01em;
        color: #192A3E;
        opacity: 0.4;
    }
    .save-filter {
        .btn-primary {
            width: 100px;
        }
    }
    button.btn.btn-filter {
        background: white;
        border-radius: 0;
        border-left: 0.3px solid #90A0B7;
        font-size: 10px;
        color: #AEAEAE;
    }
}