@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
body {
    font-family: 'Poppins', sans-serif !important;
}

th {
    font-family: 'Poppins', sans-serif !important;
}

td {
    font-family: 'Poppins', sans-serif !important;
}