@import '../sass/mixings';
// styles


.main-wrap {
    position: relative;
    height: 100vh;
    overflow: hidden;
    .background-bg {
        background-image: url("../imgs/bg-3.jpg");
        max-width: 100%;
        width: 100%;
        height: 100vh;
        position: relative;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
    .login-panel {
        @include phone {
            width: 100%;
            padding: 20px;
        }
        // position: absolute;
        // right: 0;
        margin: 0 auto;
        width: 440px;
        padding: 30px;
        background: #FFFFFF;
        box-shadow: 0px 0px px rgba(0, 0, 0, 0.25);
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        border-radius: 30px;
        .branding {
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        .login-form {
            label {
                font-size: 14px;
                color: #0e0e0e;
                font-weight: bold;
            }
            .form-control {
                border-top: none;
                border-left: none;
                border-right: none;
                border-radius: 0;
                padding-left: 0 !important;
                font-size: 14px !important;
            }
            .login-btn {
                .btn-primary {
                    margin-top: 30px;
                    width: 100%;
                    font-size: 14px;
                    background: #098EDF;
                    box-shadow: 0px 2px 6px rgba(9, 142, 223, 0.3);
                    border-radius: 4px;
                    height: 42px;
                    cursor: pointer;
                    &:hover {
                        background: #34AFF9;
                        box-shadow: 0px 8px 16px rgba(52, 175, 249, 0.2);
                        border-color: inherit !important;
                        outline: none;
                    }
                }
            }
            .forgot-pass {
                font-size: 14px;
                margin-top: 30px;
                text-align: center;
                span {
                    color: #098EDF;
                    cursor: pointer;
                    &:hover {
                        color: #34AFF9;
                    }
                }
            }
        }
    }
}


