@import '../sass/mixings';
.btn-primary {
    width: fit-content;
    height: 32px;
    font-size: 11px;
    background: #098EDF;
    box-shadow: 0px 2px 6px rgba(9, 142, 223, 0.3);
    border-radius: 4px;
    &:hover {
        background: #098EDF;
        color: white;
    }
}

.view-card {
    background: #FFFFFF;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 20px;
    margin-top: 30px;
    position: relative;
}

.card-layout-wrap {
    margin-top: 10px;
    .card-custom {
        background: #FFFFFF;
        /* box shadow */
        box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        padding: 20px;
        cursor: pointer;
        &:hover {
            box-shadow: 0px 6px 18px rgba(16, 156, 241, 0.05);
        }
        .canvas-name {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height */
            letter-spacing: 0.01em;
            color: #000000;
        }
        .deal-value {
            display: inline-grid;
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            /* identical to box height */
            letter-spacing: 0.01em;
            color: #000000;
            label {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */
                letter-spacing: 0.01em;
                color: #90A0B7;
                margin-top: 10px;
            }
        }
        .deal-stage {
            label {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */
                letter-spacing: 0.01em;
                color: #90A0B7;
                margin-top: 10px;
            }
            .label-appointemnt {
                width: 121px;
                height: 22px;
                padding: 3.5px;
                background-color: blueviolet;
                color: white;
                border-radius: 4px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.02em;
                /* white */
                color: #FFFFFF;
            }
            .label-leadin {
                width: 121px;
                height: 22px;
                padding: 3.5px;
                background-color: #FFB946;
                color: white;
                border-radius: 4px;
                font-family: Poppins;
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.02em;
            }
        }
    }
    .card-summery {
        background: #FFFFFF;
        /* box shadow */
        box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        padding: 20px;
        .total-value {
            text-align: center;
            label {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */
                letter-spacing: 0.01em;
                color: #90A0B7;
            }
            .value-sum {
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                /* identical to box height */
                letter-spacing: 0.01em;
                color: black;
            }
        }
        .tot-deal {
            margin-top: 20px;
            label {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */
                letter-spacing: 0.01em;
                color: #90A0B7;
            }
        }
        .deals-won {
            margin-top: 20px;
            label {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */
                letter-spacing: 0.01em;
                color: #90A0B7;
            }
        }
        .chart-summery {
            text-align: center;
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 18px;
            /* identical to box height */
            letter-spacing: 0.01em;
            color: #90A0B7;
            margin-top: 30px;
        }
    }
    .ad-canvas {
        border-radius: 4px;
        padding: 20px;
        font-family: Poppins;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */
        letter-spacing: 0.01em;
        color: #FFFFFF;
        background: #34AFF9;
        box-shadow: 0px 8px 16px rgba(52, 175, 249, 0.2);
        border-radius: 4px;
        text-align: center;
        margin: 0 auto;
        margin-top: 20px;
        cursor: pointer;
    }
    //leads styles
    .auto-lead-avatar {
        width: 65px;
        height: 65px;
        border-radius: 50%;
        background: #AF9BE2;
        .auto-lead-intials {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #FFFFFF;
            padding-top: 20px;
        }
    }
    .lead-name {
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: #323C47;
        margin-left: 15px;
        .call-btn {
            margin-top: 10px;
            button.btn.primary-circle-btn {
                background: none;
                color: #2ED47A;
                border: none;
                font-size: 12px;
                cursor: pointer;
                padding-left: 0;
                padding-right: 0;
                .fas {
                    margin-right: 6px;
                }
                // &:hover {
                //     color: #34AFF9;
                //     border: 1px solid #34AFF9;
                // }
                // &:active {
                //     background: #2ED47A;
                //     color: white;
                //     border: 1px solid #2ED47A;
                // }
            }
        }
    }
    .title {
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.01em;
        color: #192A3E;
    }
    .Lead-call-action {
        margin-top: 15px;
        .btn-group-toggle {
            width: 100%;
            .btn.btn-secondary {
                color: #34AFF9;
                font-size: 10px;
                background: white;
                border: 1px solid #34AFF9;
            }
            .btn-secondary:not(:disabled):not(.disabled):active,
            .btn-secondary:not(:disabled):not(.disabled).active,
            .show>.btn-secondary.dropdown-toggle {
                background-color: #34AFF9;
                color: white;
            }
        }
    }
    .lead-next {
        .btn-primary {
            width: 100%;
            height: 32px;
            font-size: 11px;
            background: #098EDF;
            box-shadow: 0px 2px 6px rgba(9, 142, 223, 0.3);
            border-radius: 4px;
        }
    }
    .feedback-text {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        /* identical to box height */
        text-align: center;
        letter-spacing: 0.01em;
        color: #90A0B7;
        text-align: center;
        margin: 0 auto;
        vertical-align: middle;
        margin-top: 100px;
    }
    .view-card.min-height {
        min-height: 301px;
        height: auto;
    }
    .add-contact {
        text-align: right;
        .btn.btn-primary {
            width: fit-content;
            font-size: 11px;
            background: #098EDF;
            box-shadow: 0px 2px 6px rgba(9, 142, 223, 0.3);
            border-radius: 4px;
            height: 32px;
        }
    }
}

.card-layout-wrap .form-group .form-control:last-child {
    border-top-left-radius: none;
    border-bottom-left-radius: none;
}

.documents {
    background: #F5F6F8;
    height: 80px;
    padding-top: 3px;
    .btn-primary {
        width: 50px;
        height: 50px;
        margin: 0 auto;
        border-radius: 50%;
        margin-top: 12px;
        background: none;
        border: 1px solid #109CF1;
        .fas {
            color: #109CF1;
        }
    }
}

.tabs.user-active-inactive {
    .nav-tabs {
        justify-content: left;
    }
}

.file-type {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #000000;
    opacity: 0.3;
    margin-top: 8px;
}

.advance-search {
    .add-canvas {
        .btn-primary {
            width: 100% !important;
        }
        margin-top: 6px;
        @include desktop {
            margin-bottom: 10px;
        }
    }
    .row {
        width: -webkit-fill-available;
        @include phone {
            width: auto !important;
        }
    }
    display: flex;
    .input-group {
        width: 50%;
        float: right;
        margin-top: 5px;
        @include phone {
            width: 100%;
            margin-top: 10px;
        }
        &:hover {
            width: 100%;
            transition: width 2s;
        }
        &:active {
            width: 100%;
        }
        &:focus {
            width: 100%;
        }
    }
    .custom-control {
        font-weight: normal;
    }
    .dropdown-toggle {
        padding-left: 10px;
        width: 40px;
        height: 40px;
        background: white;
        border: none;
    }
    .dropdown.dropdown-lg .dropdown-menu {
        padding: 15px;
    }
    input.form-control.form-control-search {
        width: 100%;
        height: 40px;
        padding: 10px;
        font-size: 12px;
        border-bottom: none !important;
        border-right: 0.3px solid #90A0B7 !important;
        border-radius: 0;
    }
    .dropdown.dropdown-lg .dropdown-menu {
        min-width: 320px;
    }
    .dropdown-menu {
        box-shadow: 1px 4px 8px -1px #c1c1c1;
    }
    .table-sort {
        margin-top: 10px;
        .filter {
            margin-left: 10px;
            cursor: pointer;
            font-size: 12px;
            padding: 5px;
            width: 30px;
            height: 30px;
            background: #FFFFFF;
            border-radius: 4px;
            text-align: center;
            float: right;
            .fas {
                color: #90A0B7;
                margin-top: 4px;
                font-size: 10px;
            }
            &:hover {
                /* box shadow */
                box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
            }
        }
    }
    input.form-control.adv-search:focus {
        border-right: 1px solid #098EDF;
        border-radius: 0;
    }
}

.advance-search.fixed-width {
    .input-group {
        width: 100%;
        &:hover {
            width: 100%;
        }
        &:active {
            width: 100%;
        }
        &:focus {
            width: 100%;
        }
    }
}

button.btn.btn-lg.btn-secondary.dropdown-toggle.dropdown-toggle-split {
    border-radius: 0;
    border: none;
    &:active {
        color: #fff;
        background-color: #098EDF;
        border-color: #098EDF !important;
    }
    &:focus {
        border-color: #098EDF !important;
    }
}

button.btn.add {
    font-size: 12px;
    color: #c1c1c1;
    &:hover {
        color: #34AFF9;
    }
}

button.delete-prim {
    font-size: 12px;
    color: #c1c1c1;
    background: none;
    border: none;
    box-shadow: none;
    &:hover {
        color: rgb(250, 87, 87);
    }
}

td.mt-10 {
    width: 20px;
}

.footer-btn-wrap {
    .preview {
        font-size: 12px;
        margin-top: 8px;
        margin-right: 20px;
        cursor: pointer;
    }
}

.filter-check-boxes {
    display: flex;
}

.canvas-value {
    display: flex;
    margin-bottom: 20px;
    .value {
        width: auto;
        margin-right: 10px;
    }
    .count {
        font-size: 12px;
        color: #c1c1c1;
        margin-top: 4px;
    }
}

.open-button {
    background-color: #555;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    position: fixed;
    bottom: 23px;
    right: 28px;
    width: 280px;
}


/* The popup form - hidden by default */

.form-popup {
    display: none;
    position: absolute;
    bottom: 14px;
    right: 15px;
    border: 3px solid #f1f1f1;
    z-index: 10;
}


/* Add styles to the form container */

.form-container {
    max-width: 300px;
    padding: 10px;
    background-color: white;
}


/* Full-width input fields */

.form-container input[type=text],
.form-container input[type=password] {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    border: none;
    background: #f1f1f1;
}


/* When the inputs get focus, do something */

.form-container input[type=text]:focus,
.form-container input[type=password]:focus {
    background-color: #ddd;
    outline: none;
}


/* Set a style for the submit/login button */

.form-container .btn {
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    opacity: 0.8;
    height: 30px;
    margin-top: 20px;
}


/* Add a red background color to the cancel button */

.form-container .cancel {
    background-color: red;
}


/* Add some hover effects to buttons */

.form-container .btn:hover,
.open-button:hover {
    opacity: 1;
}

//table
span.sort {
    color: #AEAEAE;
}

.row-clickable {
    a {
        color: #90A0B7;
    }
}

.col-md-12.height-overide {
    height: 30px;
    @include phone {
        height: auto;
    }
}

label.position {
 padding-left: 13px;
 padding-right: 13px;
 padding-top: 4px;
 padding-bottom: 4px;
 border-radius: 3px;
 background-color: #eff3ff;
 border: 1px solid #3366ff;
 position: relative;
 color:#3366ff ;
 margin-top: -22px;
    @include phone {
        height: auto;
        vertical-align: auto;
    }
}

.title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #000000;
}

//leads
.feedback-question {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #000000;
}

.view-card.add-lead-notice {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000000;
    text-align: center;
    line-height: 20px;
}

.col-9.no-padding {
    padding: 0;
}

.col-3.no-padding {
    padding: 0;
}

// dashboard stats
.block-title {
    font-size: 9px;
    font-weight: 600;
    text-align: left;
    color: #1A202C;
    white-space: nowrap;
    .fas {
        margin-right: 5px;
    }
}

.block-title.small {
    font-size: 9px;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    .fas {
        margin-right: 5px;
    }
}

.block-value-one {
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    color: #2ED47A;
    margin-top: 5px;
    position: relative;
    // &::after{
    //     content: '';
    //     font-size: 22px;
    //     right: 0;
    //     width: 2px;
    //     height: 12px;
    //     background: #3366ff;
    //     position: absolute;
    // }
    .fas {
        margin-right: 5px;
    }

}

.view-card.gradient {
 background: white;
 border:1px solid #CBD5E0;
}

.block-value-one.small {
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    margin-top: 5px;
    color: #1A202C;
}

.approve-btn {
    .btn-primary {
        width: 100%;
        color: #2ED47A;
        background-color: #ffffff;
        border-color: #2ED47A;
        box-shadow: none;
        &:hover {
            background-color: #2ED47A;
            border-color: #2ED47A;
            color: #1A202C;
        }
        .fas {
            margin-right: 5px;
        }
    }
    .btn-primary.active {
        background-color: #2ED47A !important;
        border-color: #2ED47A !important;
        color: #ffffff !important;
    }
    @include tablet {
        margin-bottom: 10px;
    }
}

.reject-btn {
    .btn-danger {
        width: 100%;
        font-size: 11px;
        color: #dc3545;
        background-color: #ffffff;
        border-color: #dc3545;
        &:hover {
            color: #fff;
            background-color: #dc3545;
            border-color: #dc3545;
        }
        .fas {
            margin-right: 5px;
        }
    }
    .btn-danger.active {
        color: #fff !important;
        background-color: #dc3545 !important;
        border-color: #dc3545 !important;
    }
}

.edit-icon {
    color: #C2CFE0;
    cursor: pointer;
    &:hover {
        color: #90A0B7;
    }
}

.owner-avatar img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: #F5F6F8;
    background-size: cover;
    -o-object-fit: cover;
    object-fit: cover;
    margin-right: 10px;
    justify-content: center;
}

.owner-avatar {
    text-align: center;
    .fas {
        margin-right: 5px;
    }
}

.wrapper {
    margin-top: 30px;
    justify-content: center;
    .ignore {
        margin-right: 15px;
    }
    .add-new-lead {
        margin-right: 15px;
    }
    .add-new-canvas {
        margin-right: 15px;
    }
    .btn-outline-primary {
        font-size: 11px;
    }
}

.view-card.fixed-height {
    min-height: 109px;
}

.tabs.usermanagent {
    .nav-tabs {
        justify-content: left;
    }
    .user-text-title {
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        text-align: center;
        margin-top: 5px;
    }
    .Canvas-owner-avatar.usermanagement {
        text-align: center;
    }
    .user-status {
        color: #2ED47A;
        font-size: 11px;
        font-weight: normal;
        margin-top: 2px;
    }
    .permission-type {
        font-size: 12px;
    }
    .permission-check {
        text-align: right;
        @include phone {
            text-align: left;
            margin-top: 5px;
        }
    }
    .enable-disable {
        float: none;
        text-align: center;
    }
    .delete-user {
        font-size: 11px;
        text-align: center;
        opacity: 0.5;
        margin-top: 5px;
        cursor: pointer;
        &:hover {
            color: #dc3545;
        }
    }
}

.reset-delete {
    font-size: 11px;
    text-align: center;
    opacity: 0.5;
    margin-top: 5px;
    cursor: pointer;
    &:hover {
        color: #098EDF;
    }
}

.permission-save {
    .btn-primary {
        width: 100%;
    }
}

.feed-para {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #818E9B;
}

.title {
    font-size: 14px;
    font-weight: bold;
    color: #323C47;
}

.d-flex-custom {
    display: flex;
}

.view-card.custom-team {
    position: relative;
    padding: 10px;
    cursor: pointer;
}

.owner-avatar.img-size.text-left {
    cursor: pointer;
    width: 40px;
    position: relative;
    margin: 0 auto;
    text-align: center;
    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #F5F6F8;
        background-size: cover;
        -o-object-fit: cover;
        object-fit: cover;
        justify-content: center;
        position: relative;
         ::before {
            content: '\f503';
            font-size: 12px;
            color: red;
        }
        &:hover {
            opacity: 0.5;
        }
    }
     ::before {
        content: '';
        color: #000000;
        font-size: 12px;
    }
}

.switch {
    text-align: center;
}

.status {
    font-size: 11px;
    color: #90A0B7;
    text-align: center;
}

.view-card.min-height {
    min-height: 173px;
}

.show-more {
    button.btn.btn-outline-primary {
        background: transparent;
        width: fit-content;
        height: 32px;
        font-size: 11px;
        color: #098EDF;
        border-color: #098EDF;
        &:hover {
            background: #098EDF;
            color: #FFFFFF;
        }
    }
}

.label-wrap {
    width: 100%;
    text-align: center;
}

.milestone-add {
    margin-top: 15px;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #098EDF;
    font-weight: 600;
    cursor: pointer;
}

.tooltip.show {
    z-index: 5;
}

.meeting-status {
    text-align: right;
    .m-status {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #000000;
        word-break: break-all;
    }
}

.m-status.success {
    color: #2ED47A;
}

.m-status.un-success {
    color: #dc3545;
}

#bootstrap-confirm-delete-container {
    .modal-content {
        height: fit-content;
    }
    .modal-dialog {
        max-width: fit-content;
        position: relative;
        right: 0;
        width: 100%;
        top: 50px;
        left: 0;
        vertical-align: middle;
        margin: auto;
        text-align: center;
        height: 400px;
        min-height: 400px;
    }
    h4#bootstrap-confirm-dialog-heading {
        display: none;
    }
    p#bootstrap-confirm-dialog-text {
        text-align: center;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.01em;
    }
    a#bootstrap-confirm-dialog-delete-btn {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.01em;
    }
    a#bootstrap-confirm-dialog-cancel-delete-btn {
        font-size: 12px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #000000;
    }
}


span.clear{
    color: #3366ff;
    cursor: pointer;
    .fas{
    margin-left: 4px;
    }
}

//scroll
::-webkit-scrollbar-track {
    border: 1px solid #A0AEC0;
    background-color: #CBD5E0;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: #CBD5E0;
}

::-webkit-scrollbar-thumb {
    background-color: #A0AEC0;
}


.list-selector{
.btn-secondary{
    background-color: white;
    border:1px solid #A0AEC0 ;
    font-size: 10px;
    width: 55px;
    height: 30px;
    color: black;
    padding-left: 0;
    &:hover{
        background-color: white;
        border:1px solid #A0AEC0 ;
        font-size: 10px;
        width: 55px;
        height: 30px;
        color: black;
    }
}
.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    position: absolute !important;
    right: 10px !important;
    top: 12px;
}

}


.title-sm{
    font-size: 12px;
    color: #818E9B;
    font-weight: bold;
}
.title-chart{
    font-size: 14px;
    color: #818E9B;
    font-weight: bold;
}

.chart{
    font-size: 16px;
    color: #141414;
    font-weight: bolder;
}

.title-lg{
    font-size: 22px;
    color: #141414;
    font-weight: bold;
}

.title.red{
    color: #dc3545;

}
.title.green{
    color: #2ED47A;
}

.filter {
    margin-left: 10px;
    cursor: pointer;
    font-size: 12px;
    padding: 5px;
    width: 30px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 4px;
    text-align: center;
    .fas {
        color: #90A0B7;
        font-size: 14px;
  
    }
}

.t-head{
    border-bottom: 1px solid #CBD5E0;
    margin-bottom: 10px;
    .form-control{
        padding-left: 10px;
        border:1px solid #A0AEC0;
        border-bottom: 1px solid #A0AEC0 !important;
        border-radius: 5px;
        &:focus{
            border:1px solid #3366ff;
        }
 
    }
    button.btn.btn-filter {
        border: 1px solid #A0AEC0;
        font-size: 12px;
        .fas{
            color: #90A0B7;
        }
    }
}


.template-wrap{
    .table-wrap{
        border: 1px solid #CBD5E0;
        border-radius: 5px;
        box-shadow: none !important;
        position: relative;
    }
    .main-tabs .canvas-view{
        margin-top: -30px;
    }
}


//preloader
/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  /* Transparent Overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    + .table-wrap{
        position: relative;
    }
  }
  
  /* :not(:required) hides these rules from IE9 and below */
  .loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  
  .loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0, rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0, rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0, rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0, rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  }
  
  /* Animation */
  
  @-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-moz-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-o-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }


  //see more
.see-more-appointment{
    .text{
        font-size: 12px;
    }

    .read-more-state {
        display: none;
      }
      
      .read-more-target {
        opacity: 0;
        max-height: 0;
        font-size: 0;
        transition: .25s ease;
      }
      
      .read-more-state:checked ~ .read-more-wrap .read-more-target {
        opacity: 1;
        font-size: inherit;
        max-height: 999em;
      }
      
      .read-more-state ~ .read-more-trigger:before {
        content: 'Show more';
      }
      
      .read-more-state:checked ~ .read-more-trigger:before {
        content: 'Show less';
      }
      
      .read-more-trigger {
        cursor: pointer;
        display: inline-block;
        padding: 0 .5em;
        color: #666;
        font-size: 12px;
        line-height: 2;
        border: 1px solid #ddd;
        border-radius: .25em;
      }
}


//post 2
.see-more-note{
    .text{
        font-size: 12px;
    }

    .read-more-state {
        display: none;
      }
      
      .read-more-target {
        opacity: 0;
        max-height: 0;
        font-size: 0;
        transition: .25s ease;
      }
      
      .read-more-state:checked ~ .read-more-wrap .read-more-target {
        opacity: 1;
        font-size: inherit;
        max-height: 999em;
      }
      
      .read-more-state ~ .read-more-trigger:before {
        content: 'Show more';
      }
      
      .read-more-state:checked ~ .read-more-trigger:before {
        content: 'Show less';
      }
      
      .read-more-trigger {
        cursor: pointer;
        display: inline-block;
        padding: 0 .5em;
        color: #666;
        font-size: 12px;
        line-height: 2;
        border: 1px solid #ddd;
        border-radius: .25em;
      }
}




//post 3
.see-more-changecanvas{
    .text{
        font-size: 12px;
    }

    .read-more-state {
        display: none;
      }
      
      .read-more-target {
        opacity: 0;
        // max-height: 20px;
        font-size: 0;
        transition: .25s ease;
      }
      
      .read-more-state:checked ~ .read-more-wrap .read-more-target {
        opacity: 1;
        font-size: inherit;
        max-height: 999em;
      }
      
      .read-more-state ~ .read-more-trigger:before {
        content: 'Show more';
      }
      
      .read-more-state:checked ~ .read-more-trigger:before {
        content: 'Show less';
      }
      
      .read-more-trigger {
        cursor: pointer;
        display: inline-block;
        padding: 0 .5em;
        color: #666;
        font-size: 12px;
        line-height: 2;
        border: 1px solid #ddd;
        border-radius: .25em;
      }
}


span.dim {
    font-size: 10px;
    display: inherit;
    /* opacity: 0.5; */
    font-weight: 400;
}

.per-value{
    font-size: 22px;
    color: #141414;
    font-weight: bold;
    text-align: center;
    .fas{
opacity: 0.5;
    }
    .green{
        color: #2ecc71 !important;
    }
    .blue{
      color:  #3498db;
    }
}


.accept{
  
        button.btn.btn-primary.accept{
            background-color: #2ecc71;
            border: none;
        }
    
}

.reject{
        button.btn.btn-primary.reject{
            background-color: #dc3545;
            border: none;
        }
}


.background{
    background-color:#f9fafb;
    .form-control{
        background-color:#f9fafb;
    }
    .form-control:focus{
        background-color:#f9fafb;
    }
}

.btn-light{
font-size: 12px;
}

.live-wire-t-wrap {
    width: 80%;
}

.flex-grow.h-10.flex.items-center {
    display: none;
}


.spinner-border{
    width: 1rem;
    height: 1rem;
    margin-left: 5px;
}

.hidden {
    .bootstrap-select .dropdown-toggle .filter-option-inner-inner{
        font-size: 14px;
    }
    button.btn.dropdown-toggle.btn-light.bs-placeholder{
        border-bottom: 1px solid #FFFFFF !important;
        &:active{
            border-bottom: 1px solid #80bdff !important;
        }
    }
    span.text:hover{
        color: unset !important;
    }
}


.red{
    color: #F32A2A !important;
}

.yellow{
    color: #FFCC00 !important;
}

.green{
    color: #2ED47A !important;
}

.red-light{
color: #D84B4B !important;
}

.change-broker-name{
    font-weight: bold;
}


.dark-bg{
    background: black;
}

.appointment-btn{
    .btn-secondary {
        color: #000000;
        background-color: #ffffff;
        border-color: #6c757d;
        font-size: 10px;
        padding-left: 5px;
    }
    .dropdown-toggle::after{
        position: absolute !important;
        right: 4px !important;
        top: 12px;
    }
    .dropdown-menu.show{
        width: fit-content !important;
    }
 
}

.col-4.no-padding-left {
    padding-left: 0;
}

 .w-fit{
width: 28%;
}


//appointment 
.a-name{
font-size: 13px;
span{
    font-weight: bold;
}
}

.w-20{
    width: 20%;
}
