body {
    background: #F5F6F8;
}

// a {
//     color: #99a6b7 !important;
// }
.call-btn {
    margin-top: 10px;
    button.btn.primary-circle-btn {
        background: none;
        color: #2ED47A;
        border: none;
        font-size: 12px;
        padding-left: 0;
        padding-right: 0;
        cursor: pointer;
        .fas {
            margin-right: 6px;
        }
        // &:hover {
        //     color: #34AFF9;
        //     border: 1px solid #34AFF9;
        // }
    }
}

.modal-dialog.center {
    position: initial;
    right: inherit;
    left: inherit;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 700px !important;
    @include phone {
        margin-top: 0;
    }
}

.modal-content.center {
    height: auto;
    @include phone {
        height: 100%;
    }
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: white !important;
}

.table-responsive {
    overflow: inherit !important;
}

//multi select overides
button.btn.dropdown-toggle.btn-light.bs-placeholder {
    font-size: 12px;
    background: none;
    border-top: 0;
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    color: black;
    border-bottom: 1px solid #D3D8DD !important;
    padding-left: 0;
    &:focus {
        border-bottom: 1px solid #80bdff !important;
        outline: 0;
    }
    // &:hover{
    //     border: 1px solid #80bdff !important;
    // }
}

button.actions-btn.bs-select-all.btn.btn-light {
    font-size: 12px;
}

button.actions-btn.bs-deselect-all.btn.btn-light {
    font-size: 12px;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    overflow: hidden;
    font-size: 12px;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
    color: #212529;
    background: none;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
}

.btn-light:hover {
    color: #000000;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: #098EDF;
    color: #ffffff;
}

.bootstrap-select .bs-ok-default:after {
    content: "";
    display: block;
    width: 0.5em;
    height: 1em;
    border-style: solid;
    border-width: 0 0.26em 0.26em 0;
    transform-style: preserve-3d;
    transform: rotate(45deg);
    color: #098EDF;
}

.bootstrap-select>.dropdown-toggle {
    position: relative;
    width: 100%;
    text-align: right;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: unset;
    border-top: 0;
    border-radius: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid #D3D8DD !important;
    padding-left: 0;
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
}

//pagination
button.page-link {
    font-size: 11px;
}

span.page-link {
    font-size: 11px;
}

.pagination {
    float: right;
}

.page-item.active .page-link {
    background: #098EDF;
    border-color: #098EDF;
}

//toogle 
.toggle-handle {
    background: #F5F6F8;
}

.toggle-off.btn {
    font-size: 12px;
}

.btn-success {
    font-size: 12px;
}

.toggle.btn {
    height: 34px !important;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-bottom: 1px solid #80bdff !important;
    outline: 0;
    box-shadow: none;
}

.bootstrap-select .dropdown-toggle:focus {
    outline: 0 !important;
    border-bottom: 1px solid #80bdff !important;
    outline: none;
    border: none;
    box-shadow: none;
}

.nav-tabs .nav-link.active {
    color: #000000 !important;
    background: none;
    position: relative;
    border-bottom: 1px solid #098EDF;
    border-radius: 0;
    font-weight: 600;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.nav-tabs .nav-link:hover {
    Text-Decoration: None !important;
}

.nav-tabs {
    border-bottom: none;
    justify-content: center;
}

.btn:focus,
.btn.focus {
    box-shadow: none !important;
}

// .bootstrap-select>.dropdown-toggle:after {
//     margin-top: -25px !important;
// }

.modal-body.height {
    min-height: 400px;
}

.modal-body{
    min-height: auto;
    height: 100%;
}


.f-12{
    font-size: 12px;
}

div#bs-select-6 {
    max-height: 220px;
}