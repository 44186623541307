@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
.canvas-view-wrap {
    margin-top: 20px;
    @include tablet {
        margin-top: 0;
    }
    .go-back {
        a {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            /* identical to box height */
            letter-spacing: 0.01em;
            color: #1A202C;
            cursor: pointer;
            .fa {
                margin-right: 5px;
                font-size: 16px;
                cursor: pointer;
                color: #1A202C;
            }
            &:hover {
                color: rgb(139, 154, 175);
            }
        }
    }
    .close-date {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.01em;
        /* text_help_gray */
        color: #818E9B;
        text-align: center;
        @include tablet {
            margin-top: 15px;
        }
        .fas {
            margin-right: 5px;
        }
    }
    .last-update {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.01em;
        /* text_help_gray */
        color: #818E9B;
        text-align: right;
        @include tablet {
            text-align: center;
            margin-top: 15px;
        }
    }
    .pipeline {
        position: relative;
        margin: 0 auto;
        text-align: center;
        margin-top: 30px;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        .checked {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #2ED47A;
            color: white;
            text-align: center;
            font-size: 27px;
            margin: 0 auto;
            position: relative;
            .fa {
                font-size: 16px;
                position: absolute;
                bottom: 11px;
                left: 11px;
                color: white;
            }
        }
        .not-checked {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 1px solid #2ED47A;
            background: #F5F6F8;
            color: #2ED47A;
            text-align: center;
            font-size: 27px;
            margin: 0 auto;
            position: relative;
            .fa {
                font-size: 16px;
                position: absolute;
                bottom: 11px;
                left: 11px;
            }
        }
        .line {
            width: 100%;
            // &::before {
            //     content: "";
            //     width: 20%;
            //     height: 2px;
            //     background: #2ED47A;
            //     position: absolute;
            //     top: 42px;
            // }
            // .line-last {
            //     width: 100%;
            // }
        }
        label {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 18px;
            /* identical to box height */
            letter-spacing: 0.01em;
            color: #90A0B7;
            width: max-content;
            text-align: center;
            margin-bottom: 10px;
        }
        @include tablet {
            overflow-x: auto;
            width: 100%;
        }
        .block {
            width: 19%;
            padding: 5px;
            background-color: #2ED47A;
            text-align: center;
            position: relative;
            &::after {
                width: 23px;
                height: 22px;
                position: absolute;
                right: -2px;
                top: 3px;
                border-top: 3px solid #F5F6F8;
                border-right: 3px solid #F5F6F8;
                transform: scaleX(0.6) rotate(45deg);
                content: " ";
                cursor: pointer;
                z-index: 10;
            }
            &::before {
                content: "";
                content: "";
                /* width: 23px; */
                /* height: 0px; */
                border-top: 13px solid transparent;
                border-bottom: 15px solid transparent;
                border-left: 10px solid #2ED47A;
                z-index: 10;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
        .block-none {
            width: 20%;
            padding: 5px;
            background-color: rgb(225, 233, 229);
            text-align: center;
            position: relative;
            &:after {
                width: 23px;
                height: 23px;
                position: absolute;
                right: -6px;
                top: 2px;
                border-top: 3px solid #F5F6F8;
                border-right: 3px solid #F5F6F8;
                transform: scaleX(0.3) rotate(45deg);
                content: " ";
                cursor: pointer;
                ;
            }
        }
        .block.one {
            border-radius: 4px 0px 0px 4px;
        }
        .block-date {
            color: #F5F6F8;
            font-size: 12px;
            font-weight: bold;
            text-align: center;
        }
    }
    .step-label {
        text-align: left;
        font-size: 12px;
        font-weight: bold;
        margin-top: 8px;
        position: absolute;
        bottom: -20px;
        left: 0px;
    }
    .view-card {
        /* white */
        background: #FFFFFF;
        /* box shadow */
        box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        padding: 20px;
        margin-top: 20px;
        .card-name {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
            /* identical to box height */
            letter-spacing: 0.01em;
            /* table_black */
            color: #323C47;
            span {
                font-weight: 600;
                font-size: 15px;
                line-height: 22px;
                /* identical to box height */
                letter-spacing: 0.01em;
                color: #2ED47A;
                margin-left: 15px;
            }
        }
        label {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */
            letter-spacing: 0.01em;
            color: #90A0B7;
            margin-top: 20px;
        }
        .sub {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            /* identical to box height */
            letter-spacing: 0.01em;
            color: #000000;
            word-break: break-all;
            span {
                margin-left: 5px;
                cursor: pointer;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.01em;
                color: #109CF1;
                .fas {
                    margin-right: 5px;
                }
                &:hover {
                    color: #2ED47A;
                }
            }
        }
        .hangup {
            span.hang {
                cursor: pointer;
                font-size: 12px;
                color: rgb(209, 31, 55);
                .fas {
                    margin-right: 5px;
                }
            }
        }
        .description {
            .form-group {
                margin-bottom: 0;
            }
            label {
                margin-top: 0px;
            }
            .text-area {
                width: 100%;
                padding: 0;
                border-bottom: 1px solid #FFFFFF !important;
                min-height: 100px;
                &:active {
                    border-bottom: 1px solid #80bdff !important;
                }
                &:focus {
                    border-bottom: 1px solid #80bdff !important;
                }
            }
            .save-text {
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                /* identical to box height */
                text-align: right;
                letter-spacing: 0.01em;
                /* Accent blue */
                color: #109CF1;
                margin-top: 18px;
                cursor: pointer;
            }
            .cancel-text {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                /* identical to box height */
                letter-spacing: 0.01em;
                color: #000000;
                opacity: 0.3;
                margin-top: 18px;
                cursor: pointer;
                margin-right: 15px;
                text-align: right;
                width: 100%;
            }
        }
        .action-tabs {
            .nav-link {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                /* identical to box height */
                letter-spacing: 0.01em;
                color: #90A0B7 !important;
                .fas {
                    font-size: 14px;
                    margin-right: 8px;
                }
                @include phone {
                    display: inherit;
                    /* text-align: center; */
                    padding-left: 0;
                }
            }
            .nav-link.no-p {
                padding-left: 0;
            }
            .nav-pills .nav-link.active {
                color: #000000 !important;
                background: none;
                .fas {
                    color: #109CF1;
                    font-size: 14px;
                    margin-right: 8px;
                }
            }
        }
        .notes {
            .text-area {
                width: 100%;
                padding: 0;
                &:active {
                    outline: 0 !important;
                    border-bottom: 1px solid #80bdff !important;
                    outline: none;
                    border: none;
                    box-shadow: none;
                }
                &:focus {
                    outline: 0 !important;
                    border-bottom: 1px solid #80bdff !important;
                    outline: none;
                    border: none;
                    box-shadow: none;
                }
            }
            .save-text {
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 19px;
                /* identical to box height */
                text-align: right;
                letter-spacing: 0.01em;
                /* Accent blue */
                color: #109CF1;
                margin-top: 10px;
                cursor: pointer;
            }
            .cancel-text {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */
                letter-spacing: 0.01em;
                color: #000000;
                opacity: 0.3;
                margin-top: 18px;
                cursor: pointer;
                margin-right: 15px;
                text-align: right;
                width: 100%;
            }
        }
        .documents {
            background: #F5F6F8;
            height: 80px;
            padding-top: 3px;
            .btn-file {
                position: relative;
                overflow: hidden;
            }
            .btn-file input[type=file] {
                position: absolute;
                top: 0;
                right: 0;
                min-width: 100%;
                min-height: 100%;
                font-size: 100px;
                text-align: right;
                filter: alpha(opacity=0);
                opacity: 0;
                background: red;
                cursor: inherit;
                display: block;
            }
            .file-input-label {
                padding: 0px 10px;
                display: table-cell;
                vertical-align: middle;
                border: 1px solid #ddd;
                border-radius: 4px;
            }
            input[readonly] {
                background-color: white !important;
                cursor: text !important;
            }
            .btn-primary {
                width: 50px;
                height: 50px;
                margin: 0 auto;
                border-radius: 50%;
                margin-top: 12px;
                background: none;
                border: 1px solid #109CF1;
                .fas {
                    margin-top: 10px;
                    color: #109CF1;
                }
                &:hover {
                    /* box shadow */
                    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
                }
            }
        }
        .file-type {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */
            letter-spacing: 0.01em;
            color: #000000;
            opacity: 0.3;
            margin-top: 8px;
        }
        .save-text {
            font-family: Poppins;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 19px;
            /* identical to box height */
            text-align: right;
            letter-spacing: 0.01em;
            /* Accent blue */
            color: #109CF1;
            margin-top: 18px;
            cursor: pointer;
        }
        .cancel-text {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            /* identical to box height */
            letter-spacing: 0.01em;
            color: #000000;
            opacity: 0.3;
            margin-top: 18px;
            cursor: pointer;
            margin-right: 15px;
            text-align: right;
            width: 100%;
        }
        .appointments {
            label {
                margin-top: 0;
            }
            .form-group {
                position: relative;
                .form-control {
                    padding-left: 0;
                    width: 100%;
                    border-bottom: 1px solid #D3D8DD !important;
                    border-radius: 0;
                    &:focus {
                        outline: 0 !important;
                        border-bottom: 1px solid #80bdff !important;
                        outline: none;
                        border: none;
                        box-shadow: none;
                    }
                }
                .fas {
                    position: absolute;
                    top: 33px;
                    right: 0;
                    color: #90A0B7;
                }
            }
        }
        label {
            font-size: 12px;
            color: #90A0B7;
        }
    }
    .view-card.main.lost-date-time {
        .remark-wrap {
            margin-right: 15px;
        }
        .lost-remarks {
            margin-top: 5px;
            font-size: 12px;
        }
        text-align: center;
        label {
            margin-top: 5px;
        }
        .lost-time {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            color: #F7685B;
            font-size: 14px;
            .fas {
                margin-right: 5px;
            }
        }
        .lost-date {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            color: #F7685B;
            font-size: 14px;
            .fas {
                margin-right: 5px;
            }
        }
    }
    .view-card.main {
        .form-group {
            margin-bottom: 0;
        }
        .form-control.hidden {
            border-bottom: 1px solid #FFFFFF !important;
            &:active {
                border-bottom: 1px solid #80bdff !important;
            }
            &:focus {
                border-bottom: 1px solid #80bdff !important;
            }
        }
        margin-bottom: 15px;
        position: relative;
        .win-lose {
            position: absolute;
            width: 100%;
            display: flex;
            bottom: -15px;
            .won {
                display: flex;
                margin: 0 auto;
                button {
                    border: 1px solid #109CF1;
                    margin-right: 15px;
                    color: #109CF1;
                    font-size: 12px;
                    width: fit-content;
                    background: white;
                    &:hover {
                        background: #2ED47A;
                        color: white;
                        border: 1px solid #2ED47A;
                    }
                    &:active {
                        background: #2ED47A;
                        color: white;
                        border: 1px solid #2ED47A;
                    }
                }
                .loss {
                    button {
                        border: 1px solid #90A0B7;
                        color: #90A0B7;
                        font-size: 12px;
                        width: fit-content;
                        &:hover {
                            color: white;
                            background: rgb(209, 31, 55);
                            border: 1px solid rgb(209, 31, 55);
                        }
                    }
                }
            }
        }
    }
}

.template-wrap {
    .activity-summary {
        margin-top: 10px;
        .nav-tabs {
            border-bottom: none;
            width: 100%;
            margin: 0 auto;
            justify-content: center;
        }
        .nav-link {
            font-family: Poppins;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            /* identical to box height */
            letter-spacing: 0.01em;
            color: #90A0B7 !important;
            .fas {
                font-size: 14px;
                margin-right: 8px;
            }
        }
        .nav-link.active {
            color: #000000 !important;
            background: none;
            position: relative;
            border-bottom: 1px solid #098EDF;
            border-radius: 0;
            font-weight: 600;
            border-left: none;
            border-right: none;
            border-top: none;
            // &:after {
            //     content: '';
            //     width: 10px;
            //     height: 10px;
            //     border-radius: 10px;
            //     background-color: royalblue;
            // }
        }
        .canvas-act-card {
            background: #FFFFFF;
            /* box shadow */
            box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
            border-radius: 4px;
            padding: 20px;
            // width: 94%;
            margin-bottom: 10px;
            margin-left: 30px;
            margin-top: 10px;
            position: relative;
            &::before {
                font-weight: 400;
                position: absolute;
                font-size: 18px;
                color: #90A0B7;
                left: -30px;
                top: 15;
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f30b";
            }
            .canvas-created {
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */
                letter-spacing: 0.01em;
                color: #000000;
            }
            .date-stamp {
                font-family: Poppins;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                /* identical to box height */
                letter-spacing: 0.01em;
                color: #90A0B7;
                margin-top: 6px;
                @include phone {
                    width: 80px;
                    white-space: initial;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .icon {
                margin-right: 15px;
                .fas {
                    color: #90A0B7;
                    font-size: 20px;
                    vertical-align: middle;
                    margin-top: 14px;
                }
            }
            .more {
                position: absolute;
                right: 10px;
                top: 10px;
                cursor: pointer;
                .fas {
                    color: #90A0B7;
                }
            }
            .play-wrap {
                position: absolute;
                right: 25px;
                text-align: center;
                .play-btn {
                    border: 1px solid #109CF1;
                    border-radius: 50%;
                    height: 30px;
                    width: 30px;
                    position: relative;
                    .fas {
                        color: #109CF1;
                        position: absolute;
                        top: 8px;
                        right: 7px;
                        font-size: 12px;
                    }
                    &:hover {
                        .fas {
                            color: white;
                        }
                        box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
                        background: #109CF1;
                        color: white;
                    }
                }
                .duration {
                    font-family: Poppins;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 10px;
                    line-height: 15px;
                    /* identical to box height */
                    letter-spacing: 0.01em;
                    color: #000000;
                    opacity: 0.3;
                    text-align: right;
                    margin-top: 5px;
                }
            }
            @include tablet {
                width: auto;
            }
        }
        li.nav-item.hide-mobile {
            @include phone {
                display: none;
            }
        }
    }
}

.sub.call-btn- {
    cursor: pointer;
    color: #109CF1;
    &:hover {
        color: #2ED47A;
    }
    .fas {
        font-size: 12px;
    }
}

.timeline-title {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #323C47;
}

.pipeline-stage {
    width: 100%;
    color: #2c3f4c;
    text-align: center;
}

//contacts view page
.close-date-contact {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #109CF1;
    text-align: center;
}

.table-wrap.margin-top {
    margin-top: 20px;
}

.col-md-8.overflow {
    height: 100%;
}

//add lead
// .wrap-to-float {
//     text-align: right;
// }
.Canvas-owner {
    height: fit-content;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    display: inline-grid;
    margin-top: 3px;
    float: left;
    label {
        text-align: right;
    }
    @include desktop {
        float: none;
        margin-top: 15px;
    }
}

.canvas-name {
    width: fit-content;
    float: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    margin-top: 11px;
    @include desktop {
        text-align: center;
        width: 100%;
    }
}

.avatar-leads-canvas {
    @include desktop {
        margin-top: 10px;
    }
}

.col-11.no-padding {
    padding-left: 0px;
    padding-right: 0;
}

.avatar-leads-canvas {
    img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: #F5F6F8;
        background-size: cover;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

.canvas-block {
    text-align: left;
    margin-top: 15px;
    font-size: 12px;
    display: grid;
    span {
        color: #818E9B;
    }
    @include desktop {
        text-align: center;
    }
}

.request-btn {
    margin-top: 30px;
    text-align: center;
    .btn-primary {
        height: fit-content;
        width: fit-content;
    }
    @include tablet {
        .btn-primary {
            width: 100%;
        }
    }
}

.feedback-questions {
    display: grid;
    width: 100%;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    .slider-range {
        margin-top: 15px;
    }
    .slider.slider-horizontal {
        width: 100%;
    }
    .slider-selection {
        background-color: #f3f3f3 !important;
        background-image: none;
    }
    .slider-handle {
        background-image: none;
        background-color: #098EDF !important;
    }
}

//stepper
.feedback-modal-wrapper {
    .nav-tabs .nav-link.active {
        color: #109CF1 !important;
        border: none;
        font-weight: 600;
    }
    .nav-tabs .nav-link {
        border: none !important;
    }
    .nav-tabs {
        border: none !important;
        justify-content: center;
    }
    .feed-para {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #818E9B;
    }
    .form-control.r-o {
        padding-left: 10px;
    }
}

.Canvas-owner-avatar {
    text-align: center;
    font-size: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: #F5F6F8;
        background-size: cover;
        -o-object-fit: cover;
        object-fit: cover;
        margin-right: 10px;
        @include phone {
            display: flex;
            margin: 0 auto;
            margin-bottom: 10px;
        }
    }
}

.status-appintment {
    margin-top: 8px;
    text-align: right;
    @include desktop {
        text-align: center;
        margin-top: 15px;
    }
    .btn-primary {
        height: fit-content;
    }
}

.center-label {
    text-align: center;
}

.team-name {
    text-align: center;
    font-size: 14px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
}

.feeback-status {
    width: 100%;
    label.width {
        width: 100%;
        span {
            float: right;
        }
    }
}

.win-or-lose {
    margin-top: 15px;
    .won-btn {
        margin-right: 10px;
        .fas {
            margin-right: 5px;
        }
    }
    .loss-btn {
        .btn-danger {
            width: fit-content;
            height: 32px;
            font-size: 11px;
        }
        .fas {
            margin-right: 5px;
        }
    }
}

.won-status {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #2ED47A;
    font-weight: 600;
    font-weight: normal;
    text-align: center;
}

.lost-status {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #F7685B;
    font-weight: 600;
    font-weight: normal;
    text-align: center;
}

.action-tabs.mobile-fix {
    @include phone {
        .nav {
            display: inline;
        }
        li.nav-item {
            width: 60%;
            margin: 0 auto;
            text-align: center;
        }
        .notes {
            margin-top: 15px;
        }
    }
}

.call-feedback-button-group {
    text-align: center;
    .btn-secondary:not(:disabled):not(.disabled).active {
        background-color: #34AFF9;
        border-color: #34AFF9;
    }
    .btn-secondary {
        color: #34AFF9;
        background-color: white;
        border-color: #34AFF9;
        font-size: 12px;
    }
}

.cancel-permission {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #000000;
    opacity: 0.3;
    margin-top: 10px;
    cursor: pointer;
    text-align: center;
    &:hover {
        color: #2c3f4c;
    }
    @include desktop {
        text-align: center;
        margin: 0;
    }
}

.info {
    .info-details {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #000000;
        span {
            margin-left: 5px;
            .fas {
                color: #098EDF;
                cursor: pointer;
            }
        }
    }
}

.title-name {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
}

.title-number {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    opacity: 0.5;
    margin-top: 5px;
}

.request-btn.contact {
    .btn-primary {
        width: 100%;
    }
}

.canvas-rejected {
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #dc3545;
    font-weight: 600;
    font-weight: normal;
    text-align: center;
}

.list-title {
    font-size: 14px;
    font-weight: bold;
}

.show-more {
    text-align: center;
    .btn-outline-primary {
        font-size: 11px;
        background-color: white;
        border-color: #005cbf;
        color: #005cbf;
        .fas {
            margin-right: 5px;
        }
        &:hover {
            color: white;
            background-color: #0062cc;
            border-color: #005cbf;
        }
    }
}

.accept-reject {
    text-align: right;
    float: right;
    margin-top: 8px;
    .approve-btn {
        margin-right: 15px;
        .btn-primary {
            width: fit-content;
        }
    }
    .reject-btn {
        .btn-danger {
            width: fit-content;
            height: 32px;
        }
    }
}


.dashboard-wrap{
    padding: 15px;
}

.left-block{
    ul{
        list-style: none;
        margin-top: 13px;
        line-height: 16px;
    }
    .fas{
        opacity: 0.5;
    }
    .day{
        text-align: right;
    }
}

.table-wrap.broker{
    padding: 5px !important;
    td{
        padding: 6px !important;
        width: 19%;
    }
}

.no-wrap{
    white-space: nowrap;
}

.col-2.no-padding{
padding-right: 0;
}

.col-lg-4{
    .white-space{
        label{
            white-space: nowrap;
        }
       
    }
}

.col-6.height {
    height: fit-content;
}


.b-value{
    white-space: nowrap;
}


