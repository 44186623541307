.title-campaign {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    letter-spacing: 0.01em;
    /* text_help_gray */
    color: #818E9B;
}

.add-campaign {
    float: right;
    .btn-primary {
        width: fit-content;
        height: 32px;
    }
}

.go-back {
    a {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #90A0B7;
        cursor: pointer;
    }
}

.view-card {
    background: #FFFFFF;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 20px;
    margin-top: 15px;
}

.view-card-campaign {
    background: #FFFFFF;
    box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 20px 5px;
    margin-top: 15px;
}

.campaign-name {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #323C47;
}

.campaign-target {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #2ED47A;
}

.campaign-members {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #000000;
}

.title-two {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #000000;
}

.campaign-end-note {
    text-align: right;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #818E9B;
}

.calls-declined {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #F7685B;
}

.enable-disable {
    float: right;
}

td.green {
    color: #2ED47A;
}

td.red {
    color: #bd2130;
}

.target-achieved {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #323C47;
}

.target-amount {
    font-weight: 600;
    color: #2ED47A;
    span {
        color: #818E9B;
        opacity: 0.4;
    }
    span.percentage {
        font-size: 12px;
        color: #818E9B;
        margin-left: 3px;
    }
}

.milestone-heading {
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #323C47;
    margin-left: 20px;
    margin-top: 15px;
}

.milestone-title {
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #323C47;
}

.milestones {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #000000;
}

.milestones.success {
    color: #2ED47A;
}

.milestone-note {
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #818E9B;
    font-weight: normal;
    opacity: 0.4;
}

.full-width {
    width: fit-content;
    float: right;
    margin-right: 20px;
    .milestone-add {
        margin-top: 15px;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #098EDF;
        font-weight: 600;
        cursor: pointer;
    }
}

.milestone-achieved {
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #2ED47A;
    font-weight: 600;
    font-weight: normal;
    text-align: center;
    .fas {
        margin-left: 5px;
    }
}